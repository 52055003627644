import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: -20vh auto 0;
  padding: 0 calc(5vw + 100px);

  ${mq('xl')} {
    padding: 0 calc(5vw);
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 9px 90px rgba(7, 13, 28, 0.25);

  ${screenSize('max-width',1440)}

  ${mq('m')} {
    flex-direction: column;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  padding: 35px 5vw;
  background: ${variables.colors.blue};
  color: ${variables.colors.white};

  ${mq('m')} {
    width: 100%;
    padding: 35px;
  }
`;

export const Choice = styled.div`
  text-align: center;
  width: 100%;

  .title {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 1.2vw;

    ${fs(12)}

    ${mq('m')} {
      margin-bottom: 20px;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0 -1.2vw;
    padding: 0;
    list-style-type: none;
    font-family: 'Bossa', sans-serif;

    ${mq('s')} {
      flex-wrap: wrap;
    }

    li {
      position: relative;
      width: calc(50% - 2.4vw);
      margin: 0 1.2vw;

      ${mq('s')} {
        width: calc(50% - 2.4vw);
        margin-bottom: 20px;
      }
    }
  }

  input[type="radio"] {
    position: absolute;
    left: -9999px;
  }

  label {
    display: block;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid ${variables.colors.white};
    cursor: pointer;
    transition: border-color .3s ease-in-out, color .3s ease-in-out;

    > span {
      display: block;
      align-items: center;
      justify-content: center;
      padding: 25px 5px 25px;
      border-radius: 4px;
      font-family: 'Bossa', sans-serif;
      
      text-align: center;
      white-space: nowrap;
      transition: background-color .3s ease-in-out;

      ${fs(12)}

      > span {
        display: inline-block;
        ${fs(14)}
      }

      strong {
        ${fs(20)}
      }
    }
  }

  input[type="radio"]:checked + label  {
    border-color: ${variables.colors.green};
    color: ${variables.colors.blue};

    > span {
      background: ${variables.colors.white};
    }
  }
`;

export const Price = styled.div`
  padding: 4vw 3vw;

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    color: ${variables.colors.white};
    margin-bottom: 10px;

    ${fs(18)}

    em {
      margin-left: 15px;
      font-weight: 400;
      font-style: normal;
      ${fs(14)}
    }
  }

  .price {
    display: flex;
    align-items: flex-end;
    font-family: 'Bossa', sans-serif;
    font-weight: 700;

    strong {
      
      line-height: 1;

      ${fs(130)}

      ${mq('m')} {
        ${fs(70)}
      }

      span {
        display: inline;
      }

      em {
        font-style: normal;

        ${fs(24)}
      }
    }

    span {
      display: block;
    }

    > span {
      padding-bottom: 10px;
      padding-left: 15px;
      font-family: 'Bossa', sans-serif;
      font-weight: 400;
      line-height: 1.5;

      ${fs(14)}

      ${mq('m')} {
        padding-bottom: 5px;
      }

      > span {
        white-space: nowrap;
      }
    }
  }

  .payFrequency {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;

    > span {
      color: ${variables.colors.white};
      transition: opacity .3s ease-in-out;
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      > span {
        opacity: .3;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
      }

      &.active {
        > span {
          opacity: 1;
        }
      }

      strong {
        font-weight: 400;
        color: ${variables.colors.green};
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        margin-left: 4px;
      } 
    }

    input {
      position: absolute;
      left: -9999px;
    }

    label {
      padding: 10px 34px;
      cursor: pointer;
    }

    .switch {
      position: relative;
      display: block;
      width: 30px;
      height: 8px;
      border-radius: 8px;
      background: ${variables.colors.grey} none;

      &-cursor {
        position: absolute;
        top: -6px;
        left: -6px;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${variables.colors.green};
        transition: left .3s ease-in-out;
      }
    }

    input:checked + label {
      .switch-cursor {
        left: calc(100% - 14px);
      }
    }
  }
`;

export const PriceContact = styled.div`
  padding: 4vw 3vw;

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    color: ${variables.colors.white};
    margin-bottom: 10px;

    ${fs(18)}

    em {
      margin-left: 15px;
      font-weight: 400;
      font-style: normal;
      ${fs(14)}
    }
  }

  .price {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    text-align: center;

    strong {
      display: inline-block;
      text-align: left;
      margin-bottom: 30px;
      line-height: 1;

      ${fs(60)}

      ${mq('m')} {
        ${fs(32)}
      }
    }

    a {
      display: inline-block;
      text-align: center;
      ${screenSize('padding-left', 65)}
      ${screenSize('padding-right', 65)}
    }
  }


`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  background: ${variables.colors.white};
  color: ${variables.colors.backgroundDark};
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${mq('m')} {
    width: 100%;
  }

  .detail {
    padding: 35px 3vw;
    height: 100%;
    flex: 1;

    ${mq('m')} {
      padding: 35px 20px;
    }
  }

  .button-note {
    display: flex;
    align-items: center;
    justify-content: center;
    ${screenSize('margin-top', 15)}
    margin-bottom: 0;
    ${fs(14)}
    color: ${variables.colors.grey};

    svg {
      margin-right: 10px;
    }
  }

  .trial {
    padding: 35px 3vw;
    background: ${variables.colors.greyLighter};

    ${mq('m')} {
      padding: 35px 20px;
    }
  }

  .title {
    margin-bottom: 10px;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.6;

    ${fs(16)}

    strong {
      font-family: 'Bossa', sans-serif;
      font-weight: 500;

      ${fs(24)}
    }

    + ul {
      margin: 35px 0 0;
    }
  }

  p {
    ${fs(16)}
  }

  ul {
    margin: 0;
    list-style-type: none;

    li {
      margin: 0;
      color: ${variables.colors.greyDarker};
      ${fs(16)}

      a {
        color: ${variables.colors.red};
        text-decoration: none;
        font-weight: 600;

        &:hover {
          color: ${variables.colors.blue};
        }
      }

      + li {
        margin-top: 20px;
      }
    }
  }
`;