import React, { useState } from "react"
import LinkTransition from "../common/link-transition"
import AnimatedNumber from "animated-number-react"

import {
  Container,
  Content,
  Main,
  Choice,
  Price,
  Description,
  PriceContact,
} from "./styled.js"
import { Button } from "../common/button"

const Pricing = props => {
  const [priceSelected, setPriceSelected] = useState(0)
  const [payFrequency, setPayFrequency] = useState("annually")

  const formatValue = value => `${Number(value).toFixed(2)}`

  return (
    <Container>
      <Content>
        <Main>
          <Choice>
            <p className="title">Choose the size of your team</p>
            <ul>
              {props.data.map((item, index) => {
                return (
                  <li key={index}>
                    <input
                      type="radio"
                      name="price"
                      id={`price_${index}`}
                      checked={priceSelected === index}
                      onChange={() => {
                        setPriceSelected(index)
                      }}
                    />
                    <label
                      htmlFor={`price_${index}`}
                      dangerouslySetInnerHTML={{
                        __html: `<span>${item.selector}</span>`,
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </Choice>
          {priceSelected === 0 ? (
            <Price>
              <p className="title">
                {props.data[priceSelected].title}
                <em>(price per user, billed {payFrequency})</em>
              </p>

              <div className="price">
                <strong>
                  <AnimatedNumber
                    value={props.data[priceSelected][payFrequency]}
                    formatValue={formatValue}
                    duration={300}
                  />
                  <em>$/month</em>
                </strong>
                {/* <strong>{props.data[priceSelected][payFrequency]}<em>$</em></strong> */}
                {/* <span>
                <span>{(payFrequency === 'monthly') ? '/ month' : '/ year'}</span>
                <span>/ user</span>
              </span> */}
              </div>

              <div className="payFrequency">
                <span
                  className={payFrequency === "monthly" ? "active" : ""}
                  onClick={() => {
                    setPayFrequency("monthly")
                  }}
                >
                  <span>Monthly</span>
                </span>
                <input
                  type="checkbox"
                  name="payFrequency"
                  id="payFrequency"
                  checked={payFrequency === "annually"}
                  onChange={() => {
                    if (payFrequency === "monthly") {
                      setPayFrequency("annually")
                    } else {
                      setPayFrequency("monthly")
                    }
                  }}
                />
                <label htmlFor="payFrequency">
                  <span className="switch">
                    <span className="switch-cursor" />
                  </span>
                </label>
                <span
                  className={payFrequency === "annually" ? "active" : ""}
                  onClick={() => {
                    setPayFrequency("annually")
                  }}
                >
                  <span>Annually -</span> <strong>pay only for 9 months</strong>
                </span>
              </div>
            </Price>
          ) : (
            <PriceContact>
              <p className="title">{props.data[priceSelected].title}</p>

              <div className="price">
                <strong>Ask for a demo and a quote</strong>
                <Button href="/contact" className="reverse">
                  Contact us
                </Button>
              </div>
            </PriceContact>
          )}
        </Main>
        <Description>
          <div className="detail">
            <h2 className="title">
              This is what you want
              <br />
              <strong>This is what you get</strong>
            </h2>
            <ul>
              <li>Unlimited team members</li>
              <li>Unlimited presentations</li>
              <li>Unlimited storage</li>
              <li>
                <LinkTransition to="/all-features">
                  ...and everything else
                </LinkTransition>
              </li>
            </ul>
          </div>
          <div className="trial">
            <h2 className="title">Your 30 days trial could start now</h2>

            <Button href="https://app.ludus.one/signup">
              Try Ludus for free
            </Button>
            <p className="button-note">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.22476 15.0056C8.22476 15.0056 9.46657 14.8977 9.81618 14.0761C10.1777 13.2259 9.49354 12.8784 9.49354 12.8784C9.49354 12.8784 10.4864 12.909 10.8536 11.8715C11.2001 10.8938 10.3567 10.4451 10.3567 10.4451C10.3567 10.4451 11.3272 10.2858 11.6104 9.25617C11.8485 8.38939 11.0051 7.74359 11.0051 7.74359H17.3376C17.835 7.74359 18.6733 7.46244 18.6738 6.42449C18.6748 5.38706 17.8132 5.15001 17.3376 5.15001H6.70389C6.18517 5.15001 6.18517 4.63129 6.70389 4.63129H8.08679C10.0538 4.63129 11.2733 3.57415 11.6099 3.2043C11.9471 2.83446 12.1177 2.3396 11.9906 1.74152C11.7754 0.726916 10.9947 0.948408 10.3323 1.23578C8.7357 1.9293 6.46061 2.40496 5.38479 2.54968C2.34615 2.95999 0.00311347 5.56291 1.17323e-06 8.71411C-0.00207369 12.1511 2.74816 15.003 6.18517 15.0056H8.22476Z"
                  fill="#FFDC5D"
                />
              </svg>
              no credit card required
            </p>
          </div>
        </Description>
      </Content>
    </Container>
  )
}

Pricing.propTypes = {}

Pricing.defaultProps = {}

export default Pricing
