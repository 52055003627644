import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"

import Hero from "../components/hero"
import PriceSelector from "../components/pricing"
import Feature from "../components/feature"
import Ready from "../components/ready"

import illuReady from "../images/illu-ready.svg"
import illuFeature from "../images/illu-wow-feature.svg"
// import visualEducation from "../images/visual-pricing.png"

const pricesData = [
  {
    title: "Ludus personal",
    selector: "<span><strong>1</strong> to <strong>15</strong></span> people",
    monthly: "19.90",
    annually: "14.90",
  },
  {
    title: "Ludus entreprise",
    selector: "<span>From <strong>16</strong></span> people",
    monthly: "13.99",
    annually: "139.99",
  },
]

const Pricing = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  return (
    <Layout
      headerDark={true}
      socialDark={true}
      currentPage="pricing"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Pricing" />

      <Section dark={true}>
        <Hero dark={true} styleType="pricing">
          <div className="content">
            <h1 className="title">Pricing</h1>
            <div className="text">
              <p>
                All our plans contain all the features.
                <br />
                Pay for the number of people using it. Enjoy the rest.
              </p>
            </div>
          </div>
        </Hero>
      </Section>
      <Section>
        <PriceSelector data={pricesData} />
      </Section>

      <Section>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "3",
            width: "25%",
            paddingBottom: "25%",
            bottom: "-6%",
            right: "0",
            background: `transparent url(${illuFeature}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <Feature
          reverse={false}
          contentFull={true}
          visual="visual-pricing.png"
          visualNotAppear={true}
          visualType="big"
          headline="Education"
          title="Education is the best investment."
          text="We have special prices for those who learn and teach."
          link="See our education pricing"
          url="/education"
        />
      </Section>

      <Section light={true}>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "4",
            width: "35%",
            paddingBottom: "35%",
            bottom: "0",
            right: "0",
            background: `transparent url(${illuReady}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />
        <Ready />
      </Section>
    </Layout>
  )
}

export default Pricing
